import { getIdentityInstance } from "./getIdentityInstance";
import { getLoginStateToken } from "./getLoginStateToken";

const login = async (): Promise<void> => {
  loginAndRedirect();
};

const loginAndRedirect = async (redirectUrl?: string): Promise<void> => {
  try {
    const identityInstance = getIdentityInstance();
    if (identityInstance) {
      const currentHref = redirectUrl || window.location.href;
      const stateToken = await getLoginStateToken(currentHref);
      identityInstance.login({
        state: stateToken,
        scope: "openid offline_access",
      });
    }
  } catch (error) {
    console.error(`Error when login: ${error}`);
  }
};

export { login, loginAndRedirect };
