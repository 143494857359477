"use client";

import { useEffect } from "react";

import { loginAndRedirect } from "../../utils/auth/login";

const getRedirectUrl = (): string => {
  if (process.env.NEXT_PUBLIC_UI_THEME === "ekonomi") {
    return "https://omniekonomi.se";
  }

  return "https://omni.se";
};

export default async function Login() {
  useEffect(() => {
    loginAndRedirect(getRedirectUrl());
  }, []);

  return null;
}
